<template>
  <div class="container-fluid">

    <div class="card search-card">
      <div class="card-body">
        <div class="form-row">
          <div class="col-sm-3 col-md-2">
            <div class="form-group">
              <label>棟別區域</label>
              <input
                type="text"
                class="form-control"
                placeholder="請輸入關鍵字"
                @keydown.stop.prevent.enter="getRestaurantTracingRule(1, tableInfo.per_page)"
                v-model.trim="Keyword"
              />
            </div>
          </div>

          <div class="col-sm-3 col-md-3">
            <button
              type="button"
              class="btn btn-primary btn-search"
              style="margin-top: 30px; margin-left: 10px"
              @click="getRestaurantTracingRule(1, tableInfo.per_page)"
            >
              {{ i18n("Buttons.Search") }}
            </button>
            <!-- <button
              type="button"
              class="btn btn-primary btn-create"
              data-toggle="modal"
              data-target="#footprintModal"
              @click="footprintModal(true)"
            >
              新增足跡
            </button> -->
          </div>
        </div>
      </div>
    </div>

    <div class="yahome-table-wrap">
      <span class="font-weight-bold">
        ※換頁及變更顯示數量會使<span class="text-danger">異動</span>資料遺失，請先<span class="text-danger">儲存資料</span>。
      </span>
      <VueBootstrap4Table :rows="rows" :columns="columns" :config="config" :classes="classes" @on-change-query="onChangeQuery" :total-rows="total">

        <!-- <template slot="name" slot-scope="props">
          <span :title="props.row.English">{{ props.row.Chinese }}</span>
        </template> -->

        <template slot="contact1" slot-scope="props">
            <v-select
                :options="tracingOptions"
                label="Name"
                :reduce="(tracingOption) => tracingOption.Value"
                v-model="props.row.Tracing1"
                @input="changeTracing(props)"
            >
            </v-select>
        </template>

        <template slot="contact2" slot-scope="props">
            <v-select
                :options="tracingOptions"
                label="Name"
                :reduce="(tracingOption) => tracingOption.Value"
                v-model="props.row.Tracing2"
                @input="changeTracing(props)"
            >
            </v-select>
        </template>

        <template slot="contact3" slot-scope="props">
            <v-select
                :options="tracingOptions"
                label="Name"
                :reduce="(tracingOption) => tracingOption.Value"
                v-model="props.row.Tracing3"
                @input="changeTracing(props)"
            >
            </v-select>
        </template>

        <template slot="contact4" slot-scope="props">
            <v-select
                :options="tracingOptions"
                label="Name"
                :reduce="(tracingOption) => tracingOption.Value"
                v-model="props.row.Tracing4"
                @input="changeTracing(props)"
            >
            </v-select>
        </template>

        <template slot="contact5" slot-scope="props">
            <v-select
                :options="tracingOptions"
                label="Name"
                :reduce="(tracingOption) => tracingOption.Value"
                v-model="props.row.Tracing5"
                @input="changeTracing(props)"
            >
            </v-select>
        </template>

        <template slot="edit" slot-scope="props">
          <button
            class="btn btn-info btn-sm"
            style="margin-right: 0.5rem"
            data-toggle="modal"
            data-target="#footprintModal"
            @click="footprintModal(false, props.row)"
          >
            {{ i18n("Buttons.Edit") }}
          </button>
        </template>

        <!-- <template slot="delete" slot-scope="props">
          <button
            class="btn btn-danger btn-delete btn-sm"
            data-toggle="modal"
            data-target="#delFootprintModal"
            @click="footprintModal(false, props.row)"
          >
            {{ i18n("Buttons.Delete") }}
          </button>
        </template> -->
        <template slot="empty-results"> 暫無資料 </template>
      </VueBootstrap4Table>
    </div>


    <!-- fiexed button -->
    <button class="btn btn-success" id="save" @click.prevent="saveRestaurantTracingRule">
        <!-- <i class="fa fa-angle-up"></i> -->
        儲存
    </button>
  </div>
</template>

<script>
/* global $ */
import { config } from "@/components/table_config.js";
import { mapGetters, mapActions } from "vuex";
import { apiGetRestaurantTracingRule, apiRestaurantTracingRuleOption, apiSaveRestaurantTracingRule } from '@/apis/tracing'


export default {

  data() {
    return {
        
      Keyword: null,
      // Modal狀態
      isAddFootprint: false,

      // 判斷是否重複
      item: {},
      nowItem: {},


      rows: [],
      columns: [
        {
          label: "棟別區域",
          name: "BuildingArea",
        },
        {
          label: "座位號碼",
          name: "SeatNo",
        },
        {
          label: "匡列1",
          slot_name: "contact1",
        },
        {
          label: "匡列2",
          slot_name: "contact2",
        },
        {
          label: "匡列3",
          slot_name: "contact3",
        },
        {
          label: "匡列4",
          slot_name: "contact4",
        },
        {
          label: "匡列5",
          slot_name: "contact5",
        },
        // {
        //   label: "",
        //   slot_name: "edit",
        // },
        {
          label: "",
          slot_name: "delete",
        },
      ],
      config: {
          ...config,
          server_mode: true,
      },
      classes: {
          tableWrapper: "table-responsive footprint-table",
          cell: {
              "text-left" : true
          },
      },
      tableInfo: {
          // 分頁資訊
          page: 1,
          per_page: 10,
      },
      total: 0,
      tracingOptions: [],
    };
  },

  computed: {
    ...mapGetters(["lang"]),
  },

  methods: {
    ...mapActions(['updateLoading']),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    // 匡列 新增&編輯&刪除 Modal
    footprintModal(isAdd, item) {
      if (!isAdd) {
        this.item = { ...item };
        // 原角色 data
        this.nowItem = {
          ...item,
        };
        this.isAddFootprint = false;
      } else {
        this.item = {};
        this.isAddFootprint = true;
      }
      this.$refs.footprint.reset();
    },

    onChangeQuery(queryParams) {
        const vm = this;
        console.log('queryParams', queryParams)
        // console.log(JSON.parse(JSON.stringify(queryParams)));

        vm.tableInfo = {
            page: queryParams.page,
            per_page: queryParams.per_page,
        };

        vm.getRestaurantTracingRule(
            vm.tableInfo.page,
            vm.tableInfo.per_page,
        );
        
    },

    //  取得全部匡列下拉
    async getRestaurantTracingRuleOption() {
        this.updateLoading(true)
        await apiRestaurantTracingRuleOption()
        .then((response) => {
            // console.log(response)

            if(response.status === 200) {
                this.tracingOptions = response.data
            }

            this.updateLoading(false)
        })
    },

    //  查詢匡列
    async getRestaurantTracingRule(page = 1, per_page = 10) {

      await this.getRestaurantTracingRuleOption()

      this.updateLoading(true)
      await apiGetRestaurantTracingRule({
          Keyword: this.Keyword,
          Page: page,
          PerPage: per_page,
      }).then((response) => {
          console.log(response)

          if(response.status === 200) {
            // this.rows = response.data.Dtos
            this.total = response.data.DataQuantity

            this.rows = response.data.Dtos.map(item => {
              if(this.tracingOptions.map(x => x.Value).indexOf(item.Tracing1)  === -1) {
                item.Tracing1 = null
              }
              
              if(this.tracingOptions.map(x => x.Value).indexOf(item.Tracing2)  === -1) {
                item.Tracing2 = null
              }

              if(this.tracingOptions.map(x => x.Value).indexOf(item.Tracing3)  === -1) {
                item.Tracing3 = null
              }

              if(this.tracingOptions.map(x => x.Value).indexOf(item.Tracing4)  === -1) {
                item.Tracing4 = null
              }
              
              if(this.tracingOptions.map(x => x.Value).indexOf(item.Tracing5)  === -1) {
                item.Tracing5 = null
              }

              return item
            })
          }

          this.updateLoading(false)
      })
    },

    changeTracing(props) {
        this.rows.forEach((item, index) => {
            if(item.SeatNoGuid === props.row.SeatNoGuid) {
                this.rows[index] = {
                    ...props.row
                }
            }
        })
    },

    //  儲存當頁匡列資料
    saveRestaurantTracingRule() {

        this.updateLoading(true)
        apiSaveRestaurantTracingRule(
            this.rows
        ).then((response) => {
            console.log(response)

            if(response.status === 200) {

                this.getRestaurantTracingRule(
                    this.tableInfo.page,
                    this.tableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功儲存",
                    type: "success",
                    duration: 3500,
                });
            }

            this.updateLoading(false)
        })
    }

  },

  async mounted() {
    // this.getRestaurantTracingRule()
    // await this.getRestaurantTracingRuleOption()

    // if(this.tracingOptions.length > 0) {
    //   await this.getRestaurantTracingRule()
    // }
  },

  destroyed() {
    $(".modal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    this.$notify.close();
  },
};
</script>

<style lang="scss" scoped>
.menu-select-list {
  overflow: auto;
  height: 100%;
  & > div {
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-bottom: 5px;

    .menu-name {
      padding: 10px;
      cursor: pointer;
      opacity: 0.5;
      display: flex;
      span {
        flex: 1;
      }
      i {
        transition: all 0.2s;
      }
    }
    .option-wrap {
      max-height: 0px;
      overflow: hidden;
      padding: 0 10px;
      transition: all 0.2s;

      .option {
        background-color: #fff;
        text-align: center;
        margin-bottom: 10px;
        border-radius: 5px;
        padding: 5px 0;
        display: block;
        cursor: pointer;
        font-size: 0.9em;
        transition: all 0.2s;

        &.active {
          background-color: #5380f7;
          color: #fff;
        }
        &.hasPermission {
          background-color: #e3e7f1;
          color: #1d4ccc;
          font-weight: bold;
        }
        &.hascheck {
          &:before {
            content: "✔";
          }
        }
      }
    }

    &.open {
      .menu-name {
        i {
          transform: rotate(90deg);
        }
      }
      .option-wrap {
        max-height: 1000px;
      }
    }
  }
}
.permission-select-list {
  label {
    display: block;
    background-color: #f1f1f1;
    margin-bottom: 5px;
    padding: 10px;
    display: flex;
    cursor: pointer;

    input[type="checkbox"] {
      display: none;
      &:checked {
        & ~ .switch {
          span {
            background-color: #5380f7;
            transform: translateX(calc(100% - 6px));
          }
        }
      }
    }
    & > span {
      flex: 1;
    }

    .switch {
      display: inline-block;
      vertical-align: middle;
      padding: 3px;
      border: 3px solid #c5c5c5;
      background-color: #fff;
      border-radius: 25px;
      width: 60px;
      position: relative;
      height: 29px;
      span {
        width: 50%;
        height: calc(100% - 6px);
        position: absolute;
        left: 3px;
        top: 3px;
        background-color: #c5c5c5;
        border-radius: 25px;
        transition: all 0.3s;
      }
    }
  }
}

div {
    /deep/ .v-select {
        width: 170px;
    }
}

</style>

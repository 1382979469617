


import req from './https'



//  餐廳匡列
export const apiGetRestaurantTracingRule = (params) => req('post', '/TracingRule/GetRestaurantTracingRule', params)
export const apiRestaurantTracingRuleOption = (params) => req('get', '/TracingRule/GetRestaurantTracingRuleDDL', params)
export const apiSaveRestaurantTracingRule = (params) => req('post', '/TracingRule/SaveRestaurantTracingRule', params)




//  吸菸區匡列
export const apiGetSmokingTracingRule = (params) => req('post', '/TracingRule/GetSmokingTracingRule', params)
export const apiSmokingTracingRuleOption = (params) => req('get', '/TracingRule/GetSmokingTracingRuleDDL', params)
export const apiSaveSmokingTracingRule = (params) => req('post', '/TracingRule/SaveSmokingTracingRule', params)



